import React, { JSX } from 'react';
import { useAccount } from '../contexts/AccountContext';
import { VerifyPage } from '../pages/account/verify';
import { SignInPage } from '../pages/signin';

/** @jsxImportSource @emotion/react */

export function withEmailVerification<T extends JSX.IntrinsicAttributes>(WrappedComponent: (props: T) => JSX.Element | null) {
  const WrappingComponent = (props: T) => {
    const account = useAccount();
    if (account === undefined) return null;
    if (!account) return <SignInPage />;
    if (!account.User.emailVerified) return <VerifyPage />;
    return <WrappedComponent {...props} />;
  };

  return WrappingComponent;
}
