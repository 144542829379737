import React from 'react'; // eslint-disable-line

import { InstantSearch } from 'react-instantsearch';
import algoliasearch from 'algoliasearch';
import { envPrefix } from './FirebaseContext';
import { useAdminAccount } from './AdminAccountContext';
import { UiState } from 'instantsearch.js';

/** @jsxImportSource @emotion/react */

type IndexName = 'USERS' | 'ARTISTPAGES' | 'ARTISTS' | 'ORDERS' | 'SONGS' | 'USEREVENTS';

export function AdminSearchContext(props: { indexName: IndexName; children: React.ReactNode; query?: string; refinementList?: { [attribute: string]: string[] }; }) {
  const adminAccount = useAdminAccount();
  if (!adminAccount) return null;
  if (!adminAccount.searchKey) return null;

  const searchClient = algoliasearch('QKXMUJRSCM', adminAccount.searchKey);
  const indexName = `${envPrefix}${props.indexName}`;
  const initialUiState: UiState = {};
  initialUiState[indexName] = { query: props.query, refinementList: props.refinementList };
  return (
    <InstantSearch searchClient={searchClient} indexName={indexName} initialUiState={initialUiState} future={{ preserveSharedStateOnUnmount: true }}>
      {props.children}
    </InstantSearch>
  );
}
