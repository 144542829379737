import React, { useState, useRef, useEffect } from 'react'; // eslint-disable-line

import t from '../theme/newstyles';
import { InputErrorInline } from './InputErrorInline';
import { Button } from './Button';
import { CheckboxProps } from './CheckboxGroup';

/** @jsxImportSource @emotion/react */

function useOnClickOutside(ref: React.RefObject<HTMLElement>, handler: () => void) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export function MultiSelect<U extends { [key: string]: string }>(props: {
  id: string;
  options: U;
  values?: string[];
  label?: string;
  invalid?: boolean;
  error?: string;
  sortingFunction?: (a: string, b: string) => number;
  listItemRender: (checkboxProps: CheckboxProps<U>) => React.ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [listFocusIndex, SetListFocusIndex] = useState<number | undefined>(0);

  const values = props.values || [];

  const optionsKeys = Object.keys(props.options);

  if (props.sortingFunction) {
    optionsKeys.sort(props.sortingFunction);
  } else {
    optionsKeys.sort();
  }

  function HandleClick(event: React.MouseEvent) {
    setOpen(!open);
  }

  function HandleKeyDown(event: React.KeyboardEvent) {
    if (open) {
      switch (event.key) {
        case 'ArrowDown': {
          if (optionsKeys.length > 0) {
            let newIndex = 0;
            if (listFocusIndex !== undefined) {
              if (listFocusIndex === optionsKeys.length - 1) newIndex = listFocusIndex;
              else newIndex = listFocusIndex + 1;
            }
            SetListFocusIndex(listFocusIndex === undefined ? 0 : newIndex);
          }

          break;
        }
        case 'ArrowUp': {
          if (listFocusIndex !== undefined) {
            if (listFocusIndex !== 0) SetListFocusIndex(listFocusIndex - 1);
          }

          break;
        }
        case 'Escape':
        case 'Tab': {
          setOpen(!open);
        }
      }
    }
  }

  useOnClickOutside(ref, () => {
    setOpen(false);
  })

  return (
    <React.Fragment>
      <div onKeyDown={HandleKeyDown} ref={ref} css={[t.relative]}>
        <Button
          type="button"
          label={props.label}
          onClick={HandleClick}
          styleType="secondary"
          innerStyle={open ? [t.border_primary_4, t.text_primary_4] : null}
        />
        { open &&
          <TagInputDropdown>
            {optionsKeys.map((option, i) => {
              const checked = values.includes(option);

              return (
                <SelectCheckbox key={i} focus={listFocusIndex === i}>
                  {props.listItemRender({
                    label: props.options[option],
                    value: option,
                    checked,
                    focused: listFocusIndex === i,
                  })}
                </SelectCheckbox>
              );
            })}
          </TagInputDropdown>
        }
      </div>
      {props.error && <InputErrorInline>{props.invalid && props.error}</InputErrorInline>}
    </React.Fragment>
  );
}

const SelectCheckbox = (props: { focus?: boolean; children?: React.ReactNode }) => {
  const focus = !!props.focus;
  return <li css={[t.pl_4, t.py_3, focus ? t.bg_tint_4 : null, t.hover([t.bg_tint_4])]}>{props.children}</li>;
};

const TagInputDropdown = (props: { children?: React.ReactNode; }) => {
  return (
    <div css={[t.absolute, t.bottom_0, t.inset_x_0]}>
      <ul
        css={[
          t.absolute,
          t.left_0,
          t.mt('18px'),
          t.pt('7px'),
          t.pb_4,
          t.bg_tint_5,
          t.border,
          t.border_solid,
          t.border_primary_4,
          t.z_50,
          t.overflow_y_scroll,
          t.min_w('300px'),
          t.max_h('300px'),
        ]}
      >
        {props.children}
      </ul>
    </div>
  );
};

export default MultiSelect;
