import React from 'react'; // eslint-disable-line

import t from '../../../../theme/admin/adminStyles';
import { FeaturedArtist } from '../../../../contexts/FeaturedArtistContentContext';
import { AdminDialog } from '../../components/Dialogs/AdminDialog';
import { AdminDialogTitle } from './AdminDialogTitle';
import { AdminSearchContext } from '../../../../contexts/AdminSearchContext';
import { AdminListControl, ListColumnDescriptor } from '../../components/AdminListControl';
import { useHits, UseHitsProps } from 'react-instantsearch-core';
import { AdminSearchTableRow, AdminSearchRowProps } from '../../components/AdminSearchTableRow';
import { ArtistOrderStats } from '../../../../contexts/ArtistProfileContext';
import { HiddenFilter } from '../AdminFilter';

/** @jsxImportSource @emotion/react */

const UserListColumns: ListColumnDescriptor<string>[] = [
  { width: '2' },
  { label: 'Name', width: '140' },
  { label: 'Artist ID', width: '15%' },
  { label: 'User ID', width: '25%' },
  { width: '80' },
];

export const ArtistListDialog = (props: {
  isOpen: boolean;
  label?: string;
  artists?: FeaturedArtist | FeaturedArtist[];
  onClose?: () => void;
  children?: (artistSearchHitProps: ArtistSearchHitProps) => React.ReactNode;
}) => {
  const [refreshKey, setRefreshKey] = React.useState(0);
  const onRefresh = React.useCallback(() => {
    setRefreshKey(refreshKey + 1);
  }, [refreshKey]);
  const filters = [{ label: 'Account Status', attribute: 'accountState', options: ['active', 'deleted'], defaults: ['active'] }];

  return (
    <AdminSearchContext indexName="ARTISTS" refinementList={{wizardState: ['complete']}}>
      <AdminDialog isOpen={props.isOpen} onRequestClose={props.onClose} dialogStyle={[t.max_w('940px')]}>
        <AdminDialogTitle>{props.label}</AdminDialogTitle>
        <div css={[t.px_8, t.py_6, t.flex, t.flex_col, t.bg_tint_4, t.shadow_md_inset]}>
          <HiddenFilter attribute="wizardState" />
          <AdminListControl listColumns={UserListColumns} sortColumn="name" filters={filters} onRefresh={onRefresh}>
            <SearchHits artists={props.artists} key={`search_${refreshKey}`}>
              {(artistHitProps: ArtistSearchHitProps) => {
                return props.children && props.children(artistHitProps);
              }}
            </SearchHits>
          </AdminListControl>
        </div>
      </AdminDialog>
    </AdminSearchContext>
  );
};

interface ArtistSearchHit {
  name: string;
  artistId: string;
  genreTags: string[];
  instrumentTags: string[];
  orderStats: ArtistOrderStats;
}

export interface ArtistSearchHitProps {
  objectID: string;
  artistHit: ArtistSearchHit;
  index: number;
  featured: boolean;
}

interface HitsProps extends UseHitsProps<ArtistSearchHit> {
  artists?: FeaturedArtist | FeaturedArtist[];
  children?: (artistSearchHitProps: ArtistSearchHitProps) => React.ReactNode;
}

const SearchHits = (props: HitsProps) => {
  const { items } = useHits(props);
  return (
    <tbody css={[t.p_0, t.text_sm, t.h_12]}>
      {items.map((hit, i: number) => {
        let featured = false;
        if (props.artists)
          featured = Array.isArray(props.artists) ? props.artists.findIndex((x) => x.id === hit.objectID) !== -1 : props.artists.id === hit.objectID;

        return (
          <AdminSearchTableRow key={i}>
            {(rowProps: AdminSearchRowProps) => {
              return (
                props.children &&
                props.children({
                  objectID: hit.objectID,
                  artistHit: hit,
                  index: i,
                  featured: featured,
                })
              );
            }}
          </AdminSearchTableRow>
        );
      })}
    </tbody>
  );
};
