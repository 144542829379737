import React from 'react'; // eslint-disable-line

import { css } from '@emotion/react';
import t from '../../theme/newstyles';
import { MainPage } from '../../components/MainPage';
import { useAccount } from '../../contexts/AccountContext';
import { Redirect, useLocation } from 'react-router-dom';
import * as H from 'history';
import { SignInPage } from '../signin';
import { EmailRedirectURL } from '../../contexts/FirebaseContext';
import { UnderlineButton } from '../../components/UnderlineButton';
import { ToastController, useToastController } from '../../contexts/ToastContext';
import { sendEmailVerification } from 'firebase/auth';

/** @jsxImportSource @emotion/react */

function UnverifiedPage() {
  const account = useAccount();

  const toastController: ToastController = useToastController();

  async function handleResend() {
    if (account) {
      try {
        await sendEmailVerification(account.User, { url: EmailRedirectURL });
        toastController.showToast('success', 'Your verification instructions have been re-sent. It may take a moment.', 5);
      } catch (error) {
        if (error instanceof Error) {
          toastController.showToast('failure', `Error: ${error.message}`, 5);
        }
      }
    }
  }

  if (!account) return null;

  return (
    <MainPage>
      <div css={[t.content_container, t.flex_auto]}>
        <div css={[t.breakpointGrid_12]}>
          <div
            css={[
              t.mt_8,
              t.mb_3,
              t.gridTypeStyle_5,
              t.max_w_full,
              t.sm([t.col_span_12, t.mt_9, t.mb_4, t.gridTypeStyle_4]),
              t.md([t.col_span_10, t.col_start_2, t.gridTypeStyle_3]),
              t.lg([t.mt_10, t.mb_5]),
              t.xl([t.col_span_9, t.col_start_2]),
              account.User.email &&
              account.User.email.length >= 32 &&
              css`
                  word-wrap: break-word;
                `,
            ]}
          >
            Email sent to {account.User.email}
          </div>
          <div
            css={[
              t.gridTypeStyle_6,
              t.col_span_12,
              t.md([t.col_span_10, t.col_start_2]),
              t.lg([t.col_span_7, t.col_start_2]),
              t.xl([t.col_span_6, t.col_start_2]),
            ]}
          >
            Check your inbox and verify your email address to get started.
          </div>
          <div
            css={[
              t.gridTypeStyle_6,
              t.flex,
              t.flex_wrap,
              t.col_span_12,
              t.md([t.col_span_10, t.col_start_2]),
              t.lg([t.col_span_7, t.col_start_2]),
              t.xl([t.col_span_6, t.col_start_2]),
            ]}
          >
            <div css={[t.mr_1]}>{`No email? Check your spam folder or `}</div>
            <UnderlineButton css={[t.underline, t.cursor_pointer]} onClick={handleResend}>
              resend verification email
            </UnderlineButton>
            .
          </div>
        </div>
      </div>
    </MainPage>
  );
}

export function useEmailVerification() {
  const account = useAccount();
  const [verified, setVerified] = React.useState(account ? account.User.emailVerified : false);
  React.useEffect(() => {
    if (!account) return;
    if (verified) return;
    if (account.User.emailVerified) {
      setVerified(true);
      return;
    }

    const checkToken = async () => {
      await account.User.getIdTokenResult(true);
      await account.User.reload();
    };

    const timer = setInterval(checkToken, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [account, verified]);

  if (!account) return false;
  return verified;
}

export const VerifyPage = () => {
  const account = useAccount();
  const verified = useEmailVerification();
  const location = useLocation<{ packageId: string; artistRoute: string }>();

  if (!account) return <SignInPage />;
  if (!verified) {
    return <UnverifiedPage />;
  }

  let isCommissioning = false;
  if (location && location.state) isCommissioning = location.state.packageId !== '' && location.state.artistRoute !== '';
  const redirectPath: H.LocationDescriptor = isCommissioning
    ? { pathname: `/artists/${location.state.artistRoute}/request`, state: { packageId: location.state.packageId } }
    : '/account/welcome';

  return <Redirect to={redirectPath} />;
};

export default VerifyPage;
