import { CreateDocumentContext, FirebaseObjects } from '../contexts/FirebaseContext';
import { v4 as uuid } from 'uuid';
import { ComputeSongPretaxPrice } from '../SongOrder';
import { GetAlbumTotalPrice } from '../AlbumOrder';
import { rejectReasons } from '../pages/projects/RejectProjectDialog';
import dayjs from 'dayjs';
import Dinero from 'dinero.js';
import { Song } from './SongContext';
import { AlbumOrderDetails, ClientSideOrder as Order, SongOrderDetails, SongPackage } from '../types/shared-types';
import firebase from 'firebase/compat/app';
import { getAuth } from 'firebase/auth';

export type OrderRole = 'artist' | 'customer';

export interface OrderComment {
  id: string;
  authorId: string;
  message: string;
  time: string;
  deleted?: boolean;
  changeRequest?: boolean;
}

export interface TaxDetails {
  name: string;
  rate: number;
  country: string;
  region: string;
  county: string;
  city: string;
  transaction_type: string;
}

export interface PriceBreakdown {
  digitalSubtotal: Dinero.DineroObject;
  digitalTax: Dinero.DineroObject;
  digitalTotal: Dinero.DineroObject;
  digitalTaxDetails: TaxDetails;

  physicalSubtotal: Dinero.DineroObject;
  physicalTax: Dinero.DineroObject;
  physicalTotal: Dinero.DineroObject;
  physicalTaxDetails: TaxDetails;

  subtotal: Dinero.DineroObject;
  totalTax: Dinero.DineroObject;
  totalAmount: Dinero.DineroObject;
}

export interface OrderCancellationReason {
  reason?: (keyof typeof rejectReasons)[];
  textField?: string;
  byAdmin?: boolean;
}

export type OrderState =
  | { type: 'pending' }
  | { type: 'active'; overdue: boolean }
  | { type: 'submitted' }
  | { type: 'review_requested' }
  | { type: 'in_review' }
  | { type: 'revision' }
  | { type: 'completed' }
  | { type: 'canceled'; reason: OrderCancellationReason };
export type OrderPaymentState = 'unprocessed' | 'authorized' | 'settled' | 'canceled' | 'distributing' | 'distributed';
export type OrderReviewReason =
  | { type: 'payment_network_failure'; time: string }
  | { type: 'payment_settlement_failure' }
  | { type: 'payment_distribution_failure' }
  | { type: 'comment'; id: string }
  | { type: 'negative_commissioner_experience' };

export type OrderHistoryEvent =
  | {
    type: 'created';
    time: string;
    initiator: 'system' | string;
  }
  | {
    type: OrderState['type'];
    time: string;
    initiator: 'system' | string;
  }
  | {
    type: 'paymentCancelationSubmitted' | 'paymentSettled' | 'paymentDistributionSubmitted' | 'paymentDistributed' | 'paymentRefunded';
    time: string;
    initiator: 'system' | string;
  }
  | {
    type: 'issueRaised';
    time: string;
    initiator: 'system' | string;
    issueType: OrderReviewReason['type'];
  }
  | {
    type: 'issueBeingReviewed';
    time: string;
    initiator: 'system' | string;
    issueType: OrderReviewReason['type'];
  }
  | {
    type: 'issueResolved';
    time: string;
    initiator: string;
    issueType: OrderReviewReason['type'];
  }
  | {
    type: 'commentDeleted';
    time: string;
    initiator: string;
  }
  | {
    type: 'sharingChanged';
    time: string;
    initiator: string;
    state: boolean;
  }
  | {
    type: 'completedCommissionRatingFollowUp';
    time: string;
    initiator: string;
  };

type Vocals = 'empty' | 'included' | 'none';

export type AlbumPackage = {
  type: 'album';
  order: number;

  name: string;
  description: string;
  price: Dinero.DineroObject;
  deliveryWeeks: number;
};

export const VocalLabels: { [key in Vocals]: string } = {
  empty: '',
  included: 'Vocals',
  none: 'No vocals',
};

const OrderDocumentContext = CreateDocumentContext<Order>('/orders');
export const OrderProvider = OrderDocumentContext.Provider;
export const useOrder = OrderDocumentContext.Use;

export interface EditDeliverableRequest {
  orderId: string;
  song?: { id: string; uploadId: string; isrc: string };
  genre?: string;
  videoGreetingUploadId?: string;
  videoPerformanceUploadId?: string;
  lyricsMailed?: boolean;
}

export function GetNumberOfDeliverables(order: Order) {
  let count = 1;

  switch (order.order.type) {
    case 'song': {
      if (order.order.details.videoGreeting) ++count;
      if (order.order.details.videoPerformance) ++count;
      if (order.order.details.handWrittenLyrics) ++count;
      if (order.order.details.vinyl7in) ++count;
      break;
    }
  }

  return count;
}

export function GetDeliveryWeeks(order: Order): number {
  switch (order.order.type) {
    case 'song': {
      if (order.order.details.rushOrder) return 1;
    }
  }

  return order.order.package.deliveryWeeks;
}

export function AssetsAreReady(order: Order) {
  return order.state.type === 'completed' || order.state.type === 'submitted' || order.state.type === 'revision';
}

export async function EditDeliverable(request: EditDeliverableRequest) {
  const result = await FirebaseObjects.artistLongRequest({
    action: 'artistEditSongOrder',
    data: { action: 'editDeliverable', ...request },
  });
  return result.data;
}

export interface UploadState {
  uploadTask: firebase.storage.UploadTask;
  uploadId: string;
  state: 'uploading' | 'transcoding' | 'finalizing' | 'canceled' | 'error' | 'complete';
}

export function UploadFile(file: File | null): UploadState | null {
  if (file === null) return null;
  const currentUser = getAuth().currentUser;
  if (!currentUser) return null;

  const uid = currentUser.uid;
  const filename = `${uuid()}`;
  const docref = FirebaseObjects.uploadBucket.ref(`${uid}/${filename}`);
  const uploadTask = docref.put(file);
  return {
    uploadTask: uploadTask,
    uploadId: filename,
    state: 'uploading',
  };
}

export interface FileUploadState {
  task: firebase.storage.UploadTask;
  uploadId: string;
  state: 'uploading' | 'canceled' | 'error' | 'complete';
  bytesTransferred: number;
  bytesTotal: number;
  mounted: boolean;
  update: number;
}

export function StartFileUpload(file: File): FileUploadState | null {
  const currentUser = getAuth().currentUser;
  if (!currentUser) return null;

  const uid = currentUser.uid;
  const filename = `${uuid()}`;
  const docref = FirebaseObjects.uploadBucket.ref(`${uid}/${filename}`);
  const uploadTask = docref.put(file);
  return {
    task: uploadTask,
    uploadId: filename,
    state: 'uploading',
    bytesTotal: 0,
    bytesTransferred: 0,
    mounted: true,
    update: 0,
  };
}

export interface TranscodeSongResult {
  uploadId: string;
  isrc: string;
}

export async function TranscodeSong(uploadId: string, orderId: string, songId: string, title: string, genre: string) {
  const response = await FirebaseObjects.artistLongRequest({ action: 'transcodeSong', data: { uploadId, orderId, songId, title, genre } });
  return response.data as TranscodeSongResult | null;
}

export function DownloadDeliveryMediaZip(zipUrl: string) {
  const link = document.createElement('a');
  link.href = zipUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function MarkComplete(orderId: string, deliveryMessage: string) {
  await FirebaseObjects.artistRequest({
    action: 'artistEditSongOrder',
    data: { action: 'complete', orderId, deliveryMessage },
  });
}

export async function MarkAccepted(orderId: string) {
  await FirebaseObjects.artistRequest({
    action: 'artistEditSongOrder',
    data: { action: 'accept', orderId },
  });
}

export async function MarkCanceled(orderId: string, reason: OrderCancellationReason) {
  const reasonObject: any = reason; // eslint-disable-line @typescript-eslint/no-explicit-any
  for (const key in reasonObject) {
    if (reasonObject[key] === undefined || reasonObject[key] === '') delete reasonObject[key];
  }
  await FirebaseObjects.artistRequest({
    action: 'artistEditSongOrder',
    data: { action: 'cancel', orderId, reason },
  });
}

export const commentedOrderIds: string[] = [];
export function AddCommentedOrderId(orderId: string) {
  commentedOrderIds.push(orderId);
}

export async function ArtistComment(orderId: string, message: string) {
  await FirebaseObjects.artistRequest({
    action: 'artistEditSongOrder',
    data: { action: 'comment', orderId, message },
  });
}

export async function ArtistClearUnreadComments(orderId: string) {
  await FirebaseObjects.artistRequest({
    action: 'artistEditSongOrder',
    data: { action: 'clearUnreadComments', orderId },
  });
}

export async function ArtistReportComment(orderId: string, commentId: string) {
  await FirebaseObjects.artistRequest({
    action: 'artistEditSongOrder',
    data: { action: 'reportComment', orderId, commentId },
  });
}

export async function ArtistDeleteComment(orderId: string, commentId: string) {
  await FirebaseObjects.artistRequest({
    action: 'artistEditSongOrder',
    data: { action: 'deleteComment', orderId, commentId },
  });
}

export async function CustomerComment(orderId: string, message: string) {
  await FirebaseObjects.userRequest({
    action: 'userEditSongOrder',
    data: { action: 'comment', orderId, message },
  });
}

export async function CustomerClearUnreadComments(orderId: string) {
  await FirebaseObjects.userRequest({
    action: 'userEditSongOrder',
    data: { action: 'clearUnreadComments', orderId },
  });
}

export async function CustomerReportComment(orderId: string, commentId: string) {
  await FirebaseObjects.userRequest({
    action: 'userEditSongOrder',
    data: { action: 'reportComment', orderId, commentId },
  });
}

export async function CustomerDeleteComment(orderId: string, commentId: string) {
  await FirebaseObjects.userRequest({
    action: 'userEditSongOrder',
    data: { action: 'deleteComment', orderId, commentId },
  });
}

export async function CustomerRequestRevision(orderId: string, message: string) {
  await FirebaseObjects.userRequest({
    action: 'userEditSongOrder',
    data: { action: 'revision', orderId, message },
  });
}

export async function CustomerModifySharing(orderId: string, isPublic: boolean) {
  await FirebaseObjects.userRequest({
    action: 'userEditSongOrder',
    data: { action: 'editSharing', orderId, isPublic },
  });
}

export async function GetVideoStreamUrl(orderId: string, video: 'greeting' | 'performance'): Promise<string> {
  const response = await FirebaseObjects.visitorRequest({
    action: 'getVideoStreamUrl',
    data: { orderId, video },
  });
  return response.data.url as string;
}

export async function DownloadVideo(orderId: string, video: 'greeting' | 'performance') {
  const response = await FirebaseObjects.userRequest({
    action: 'getVideoDownloadUrl',
    data: { orderId, video },
  });

  if (response.data.url) {
    const link = document.createElement('a');
    link.href = response.data.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function GetOrderTotalPrice(order: Order) {
  const { package: orderPackage, details } = order.order;
  const discount = order.discountPercentageApplied || 0;
  switch (order.order.type) {
    case 'song': {
      return ComputeSongPretaxPrice(orderPackage as SongPackage, details as SongOrderDetails, discount);
    }
    case 'album': {
      return GetAlbumTotalPrice(orderPackage as AlbumPackage, details as AlbumOrderDetails, discount);
    }
  }
}

export function GetUnreadCommentString(order: Order, role: OrderRole) {
  let unreadCommentCount = 0;
  if (order.unreadCommentCounts) {
    const id = role === 'artist' ? order.artistId : order.customerId;
    unreadCommentCount = order.unreadCommentCounts[id] || 0;
  }

  return unreadCommentCount ? `${unreadCommentCount} new comment${unreadCommentCount > 1 ? 's' : ''}` : '';
}

export interface StartDateResponse {
  normalStartDate: string;
  rushStartDate: string;
  error: boolean;
  bookingError: boolean;
}
export async function GetArtistStartDate(artistRoute: string, packageId: string) {
  try {
    const response = await FirebaseObjects.visitorRequest({
      action: 'getStartDate',
      data: { artistRoute, packageId },
    });
    if (response.data.error) return { normalStartDate: '', rushStartDate: '', error: true, bookingError: false };
    if (response.data.bookingError) return { normalStartDate: '', rushStartDate: '', error: true, bookingError: true };
    return { ...response.data, error: false, bookingError: false } as StartDateResponse;
  } catch (error) {
    console.log(error);
    return { normalStartDate: '', rushStartDate: '', error: true };
  }
}

export function CanCancelOrder(order: Order): boolean {
  switch (order.state.type) {
    case 'active':
    case 'revision':
    case 'pending':
    case 'submitted':
      return true;
  }

  return false;
}

export function CanRefundOrder(order: Order): boolean {
  return !order.braintreeTransactionRefunded;
}

export async function AdminUseVideo(orderId: string, video: 'greeting' | 'performance', usage: 'download' | 'stream'): Promise<string | null> {
  const result = await FirebaseObjects.adminRequest({ action: 'adminUseVideo', data: { orderId: orderId, video: video, usage: usage } });
  if (usage === 'download' && result.data.url) {
    const link = document.createElement('a');
    link.href = result.data.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return result.data.url;
}

export async function AdminCompletedOrderRatingsRequest(orderId: string) {
  await FirebaseObjects.adminRequest({ action: 'completedOrderRatingsRequest', data: { orderId: orderId } });
}

export function ToShortProjectTimeFormat(date: string) {
  return dayjs().isSame(date, 'year') ? dayjs(date).format('DD MMM') : dayjs(date).format('DD MMM, YYYY');
}

export function ToProjectTimeFormat(date: string) {
  return dayjs().isSame(date, 'year') ? dayjs(date).format('MMM Do') : dayjs(date).format('MMM Do, YYYY');
}

export function ToLongProjectTimeFormat(date: string) {
  return dayjs().isSame(date, 'year') ? dayjs(date).format('MMMM Do') : dayjs(date).format('MMMM Do, YYYY');
}

export function OrderIsComplete(order: Order, song: Song) {
  if (song && song.state !== 'uploaded') {
    return true;
  }
  if (order.order.type === 'song') {
    if (order.order.details.videoGreeting && !order.order.deliverable.videoGreetingId) return true;
    if (order.order.details.videoPerformance && !order.order.deliverable.videoPerformanceId) return true;
    if (order.order.details.handWrittenLyrics && !order.order.deliverable.lyricsMailed) return true;
  }
  return false;
}

export async function AdminHandlePayout(orderId: string) {
  await FirebaseObjects.adminRequest({ action: 'handlePayout', data: { orderId } });
}
