import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { AdminSearchContext } from '../../../contexts/AdminSearchContext';
import { useHits, UseHitsProps } from 'react-instantsearch-core';
import { UserEvent, GetUserEventLabel } from '../UserEvents';
import { HiddenFilter } from '../components/AdminFilter';
import { AdminEventList } from '../components/AdminEventList';
import { AdminEventListItem } from '../components/AdminEventListItem';
import { AdminDetailsLinkButton } from '../components/Dialogs/AdminDetailsLinkButton';

/** @jsxImportSource @emotion/react */

export const AdminDialogUserHistory = (props: { uid: string }) => {
  return (
    <AdminSearchContext indexName="USEREVENTS" refinementList={{userId: [props.uid]}}>
      <div css={[t.bg_tint_4, t.border_b, t.border_solid, t.border_tint_3, t.pt_3, t.flex_auto, t.flex, t.flex_col]}>
        <div css={[t.px_6, t.text_sm, t.text_tint_1]}>Event History</div>
        <AdminEventList>
          <HiddenFilter attribute="userId" />
          <AdminUserEventHits />
        </AdminEventList>
      </div>
    </AdminSearchContext>
  );
};

type UserEventHit = UserEvent & {
  objectID: string;
};

const AdminUserEventHits = (props: UseHitsProps<UserEventHit>) => {
  const { items } = useHits(props);
  return (
    <React.Fragment>
      {items.map((hit, i) => {
        let eventDetails: React.ReactNode | null = null;
        switch (hit.type) {
          case 'ArtistCommissioned': {
            eventDetails = (
              <AdminDetailsLinkButton to={`/admin/orders/${hit.orderId}/[""]`} containerCSS={[t.mt_0, t.ml_2, t.text_xs]}>
                View order
              </AdminDetailsLinkButton>
            );
            break;
          }
          case 'ArtistPaid': {
            eventDetails = (
              <AdminDetailsLinkButton to={`/admin/orders/${hit.orderId}/[""]`} containerCSS={[t.mt_0, t.ml_2, t.text_xs]}>
                View order
              </AdminDetailsLinkButton>
            );
            break;
          }
        }
        return (
          <AdminEventListItem key={i} label={GetUserEventLabel(hit)} timeStamp={hit.time}>
            {eventDetails}
          </AdminEventListItem>
        );
      })}
    </React.Fragment>
  );
};
