/** @jsxImportSource @emotion/react */

import * as dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import dayjsRelative from 'dayjs/plugin/relativeTime';
import dayjsAdvancedFormat from 'dayjs/plugin/advancedFormat';

import React, { Suspense } from 'react'; // eslint-disable-line
import { Global, css } from '@emotion/react';
import t from './theme/newstyles';
import { HelmetProvider } from 'react-helmet-async';
import GlobalHeaderV2, { CustomeHeaderBannerProvider } from './components/headers/GlobalHeaderV2';
import { BrowserRouter as Router, Switch, Route, Redirect, useParams } from 'react-router-dom';
import { AccountProvider, useAccount } from './contexts/AccountContext';
import { ArtistProfileProvider } from './contexts/ArtistProfileContext';
import { ArtistOrderListProvider, CustomerOrderListProvider } from './contexts/OrderListContext';
import { SwitchesProvider } from './contexts/SwitchesContext';
import { SongUploadProvider } from './contexts/SongUploadContext';
import { SongPlayingProvider } from './contexts/SongPlayingContext';
import { ToastProvider } from './contexts/ToastContext';
import { ToastHeader } from './components/toasts/ToastHeader';
import { ArtistSearchProvider } from './contexts/ArtistSearchContext';
import { PackageSearchProvider } from './contexts/PackageSearchContext';
import { ScrollToTop } from './components/ScrollToTop';
import { Footer } from './components/footers/Footer';
import { FeaturedArtistsProvider } from './contexts/FeaturedArtistContext';
import { BehaviorProvider } from './contexts/BehaviorContext';
import { CookieBanner } from './components/CookieBanner';
import { InitializeFirebase } from './contexts/FirebaseContext';
import { AccountFooter } from './components/footers/AccountFooter';
import countries from './countries.json';
import { AdminRouter } from './pages/admin/AdminRouter';
import { SiteWidePromoProvider } from './contexts/SiteWidePromoContext';
import { PerfectMatchProvider } from './contexts/PerfectMatchContext';
import { OrderProposalProvider } from './contexts/OrderProposalContext';

const EditArtistPage = React.lazy(() => import('./pages/editArtistProfile/EditArtistPage'));
const AccountManagement = React.lazy(() => import('./pages/account/AccountManagement'));
const OrderList = React.lazy(() => import('./pages/orders/OrderList'));
const ArtistPageRouter = React.lazy(() => import('./pages/artistProfile/ArtistPageRouter'));
const ArtistDashboard = React.lazy(() => import('./pages/dashboard/ArtistDashboard'));
const Projects = React.lazy(() => import('./pages/projects/Projects'));
const NotedPage = React.lazy(() => import('./pages/noted/NotedPage'));
const NotificationPage = React.lazy(() => import('./pages/NotificationPage'));
const ArtistSearch = React.lazy(() => import('./pages/search/ArtistSearch'));
const PackageSearch = React.lazy(() => import('./pages/packageSearch'));
const CommunityGuidelines = React.lazy(() => import('./pages/legal/CommunityGuidelines'));
const PrivacyPolicy = React.lazy(() => import('./pages/legal/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('./pages/legal/TermsOfService'));
const HelpCenterPage = React.lazy(() => import('./pages/FAQ/HelpCenterPage'));
const CommissionerAgreement = React.lazy(() => import('./pages/legal/CommissionerAgreement'));
const CreatorAgreement = React.lazy(() => import('./pages/legal/CreatorAgreement'));
const PolicyArchive = React.lazy(() => import('./pages/legal/PolicyArchive'));
const InfringementPolicy = React.lazy(() => import('./pages/legal/InfringementPolicy'));
const CookiePolicy = React.lazy(() => import('./pages/legal/CookiePolicy'));
const CommissionTypes = React.lazy(() => import('./pages/standalonePages/CommissionTypes'));
const AboutUs = React.lazy(() => import('./pages/AboutUs'));
const SignInPage = React.lazy(() => import('./pages/signin'));
const SignUpPage = React.lazy(() => import('./pages/signup'));
const NotFound = React.lazy(() => import('./pages/errors/NotFound'));
const InvalidSong = React.lazy(() => import('./pages/errors/InvalidSong'));
const MailingListSignup = React.lazy(() => import('./pages/MailingListSignup'));
const ChooseSignUp = React.lazy(() => import('./pages/ChooseSignUp'));
const SongRequestThankYou = React.lazy(() => import('./pages/songRequest/SongRequestThankYou'));
const ServerError = React.lazy(() => import('./pages/errors/ServerError'));
const SongDeliveryView = React.lazy(() => import('./pages/songDelivery/SongDeliveryView'));
const SongDeliveryViewPublic = React.lazy(() => import('./pages/songDelivery/SongDeliveryViewPublic'));
const CreateArtistWizard = React.lazy(() => import('./pages/onboarding/CreateArtistWizard'));
const Unauthorized = React.lazy(() => import('./pages/errors/Unauthorized'));
const OrderView = React.lazy(() => import('./pages/orders/OrderView'));
const ProjectView = React.lazy(() => import('./pages/projects/ProjectView'));
const BecomeArtist = React.lazy(() => import('./pages/becomeAnArtist/BecomeArtist'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const Analytics = React.lazy(() => import('./pages/dashboard/Analytics'));
const WelcomePage = React.lazy(() => import('./pages/account/WelcomePage'));
const VerifyPage = React.lazy(() => import('./pages/account/verify'));
const LandingPage = React.lazy(() => import('./pages/landingPage/LandingPage'));
const UpdateArtistRatingPage = React.lazy(() => import('./pages/artistRatings/UpdateArtistRatingPage'));
const CompletedCommissionRatingPage = React.lazy(() => import('./pages/artistRatings/CompletedCommissionRatingPage'));
const PrivatePageWarning = React.lazy(() => import('./pages/songDelivery/PrivatePageWarning'));

// Policy Archive pages
const TermsOfService_2021_04_06 = React.lazy(() => import('./pages/legal/archive/TermsOfService/TermsOfService-2021-04-06'));
const TermsOfService_2021_08_17 = React.lazy(() => import('./pages/legal/archive/TermsOfService/TermsOfService-2021-08-17'));
const CWLA_2021_04_07 = React.lazy(() => import('./pages/legal/archive/CWLA/CWLA-2021-04-07'));
const CWLA_2021_08_17 = React.lazy(() => import('./pages/legal/archive/CWLA/CWLA-2021-08-17'));
const CreatorAssignment_2021_04_07 = React.lazy(() => import('./pages/legal/archive/CreatorAssignment/CreatorAssignment-2021-04-07'));

// eslint-disable-next-line @typescript-eslint/no-var-requires
const smoothscroll = require('smoothscroll-polyfill');
smoothscroll.polyfill();

dayjs.extend(dayjsUTC);
dayjs.extend(dayjsRelative);
dayjs.extend(dayjsAdvancedFormat);

// This styling code is a workaround for weird behavior of vh on mobile devices
// More info here: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const minHeight = css`
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
`;

const rootCSS = [t.flex, t.flex_col, t.justify_between, t.z_10, minHeight];

function MainRouter() {
  const account = useAccount();
  let artistId: string | null = null;
  let uid: string | null = null;

  uid = account ? account.User.uid : '';
  if (account && account.Claims.isArtist) artistId = uid;

  const [auth, setUnauthorized] = React.useState({ authorized: false, checked: false });
  React.useEffect(() => {
    const asyncCall = async () => {
      try {
        const res = await fetch('https://get.geojs.io/v1/ip/country.json');
        const data = await res.json();
        // console.log(data);
        const countryCodes = countries.countryList.map((country) => country.code);
        const authorized = [...countryCodes].indexOf(data.country) > -1;
        setUnauthorized({ authorized, checked: true });
      } catch (err) {
        console.log(err);
        setUnauthorized({ authorized: true, checked: true });
      }
    };
    asyncCall();
  }, []);

  if (!auth.checked) return null;

  return (
    <ToastProvider>
      <SongUploadProvider>
        <ArtistProfileProvider artistId={artistId}>
          <ArtistOrderListProvider artistId={artistId}>
            <CustomerOrderListProvider customerId={uid}>
              <FeaturedArtistsProvider>
                <BehaviorProvider>
                  <CustomeHeaderBannerProvider>
                    <PackageSearchProvider>
                      <PerfectMatchProvider>
                        <OrderProposalProvider>
                          <div css={rootCSS}>
                            {!auth.authorized ? (
                              <Unauthorized />
                            ) : (
                              <React.Fragment>
                                <GlobalHeaderV2 artistId={artistId} uid={uid} />
                                <ToastHeader />
                                <PageRouter artistId={artistId} uid={uid} />
                                <div id="modalRoot"></div>
                                <FooterRouter />
                              </React.Fragment>
                            )}
                          </div>
                        </OrderProposalProvider>
                      </PerfectMatchProvider>
                    </PackageSearchProvider>
                  </CustomeHeaderBannerProvider>
                </BehaviorProvider>
              </FeaturedArtistsProvider>
            </CustomerOrderListProvider>
          </ArtistOrderListProvider>
        </ArtistProfileProvider>
      </SongUploadProvider>
    </ToastProvider>
  );
}

function FooterRouter() {
  return (
    <Switch>
      <Route path="/artists/:artistId/:pageName" />
      <Route path="/update-rating/:orderId/:artistName" />
      <Route path="/rate-completed-commission/:orderId" />
      <Route path="/private-page-warning" />
      <Route path="/edit-profile" />
      <Route path="/account" />
      <Route path="/create" />
      <Route path="/signup" />
      <Route path="/signin" />
      <Route path="/artist-signup" />
      <Route path="/commissioner-signup" />
      <Route path="/notifications" component={AccountFooter} />
      <Route path="/commissions" component={AccountFooter} />
      <Route path="/commissions/:orderId" component={AccountFooter} />
      <Route exact path="/projects" component={AccountFooter} />
      <Route path="/projects/:orderId" />
      <Route path="dashboard" component={AccountFooter} />
      <Route path="/share" />
      <Route path="/" component={Footer} />
    </Switch>
  );
}

function SignUpArtist() {
  return <SignUpPage asArtist />;
}

function ArtistRouter() {
  return (
    <Switch>
      {/* <Route path="/artists/:artistId" component={ArtistPageRouter} /> */}
      <Route
        path="/artists/:artistId"
        render={(routeProps: any) => {
          return <ArtistPageRouter {...routeProps} />;
        }}
      />
      <ArtistSearchProvider>
        <Route exact path="/artists" component={ArtistSearch} />
      </ArtistSearchProvider>
      <Route component={NotFound} />
    </Switch>
  );
}

function EmailRedirectArtistPackage() {
  const params = useParams<{ artistRoute: string; packageId: string }>();
  return <Redirect to={{ pathname: `/artists/${params.artistRoute}/request`, state: { packageId: params.packageId } }} />;
}

function PolicyArchiveRouter() {
  return (
    <Switch>
      <Route path="/archive/terms-2021-04-06" component={TermsOfService_2021_04_06} />
      <Route path="/archive/terms-2021-08-17" component={TermsOfService_2021_08_17} />
      <Route path="/archive/cwla-2021-04-07" component={CWLA_2021_04_07} />
      <Route path="/archive/cwla-2021-08-17" component={CWLA_2021_08_17} />
      <Route path="/archive/creator-assignment-2021-04-07" component={CreatorAssignment_2021_04_07} />
      <Route component={NotFound} />
    </Switch>
  );
}

function PageRouter(props: { artistId: string | null; uid: string | null }) {
  return (
    <Suspense fallback={<div>&nbsp;</div>}>
      <Switch>
        <Route exact path="/mailing-list-signup/:token" component={MailingListSignup} />
        <Route exact path="/emails/package-redirect/:artistRoute/:packageId" component={EmailRedirectArtistPackage} />
        <Route exact path="/signup" component={ChooseSignUp} />
        <Route exact path="/artist-signup" component={SignUpArtist} />
        <Route exact path="/commissioner-signup" component={SignUpPage} />
        <Route exact path="/signin" component={SignInPage} />
        <Route exact path="/become-an-artist" component={BecomeArtist} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route path="/artists" component={ArtistRouter} />
        <Route path="/match" render={() => <PackageSearch />} />
        <Route exact path="/commissions" component={OrderList} />
        <Route exact path="/commissions/:orderId" component={OrderView} />
        <Route exact path="/account/verify" component={VerifyPage} />
        <Route exact path="/account/welcome" component={WelcomePage} />
        <Route path="/account" component={AccountManagement} />
        <Route exact path="/dashboard" component={ArtistDashboard} />
        <Route exact path="/noted" component={NotedPage} />
        <Route exact path="/notifications" component={NotificationPage} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/projects/:orderId" component={ProjectView} />
        <Route exact path="/share/:type/:orderId/public" component={SongDeliveryViewPublic} />
        <Route path="/share/:type/:orderId" component={SongDeliveryView} />
        <Route exact path="/update-rating/:orderId/:artistName" component={UpdateArtistRatingPage} />
        <Route exact path="/rate-completed-commission/:orderId" component={CompletedCommissionRatingPage} />
        <Route exact path="/private-page-warning" component={PrivatePageWarning} />
        <Route exact path="/analytics" component={Analytics} />
        <Route path="/create" component={CreateArtistWizard} />
        <Route path="/thanks" component={SongRequestThankYou} />
        <Route path="/edit-profile" component={EditArtistPage} />
        <Route exact path="/discover" component={LandingPage} />
        <Route exact path="/about" component={AboutUs} />
        <Route path="/help-center" component={HelpCenterPage} />
        <Route exact path="/terms" component={TermsOfService} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/cookie-policy" component={CookiePolicy} />
        <Route exact path="/community-guidelines" component={CommunityGuidelines} />
        <Route exact path="/infringement-policy" component={InfringementPolicy} />
        <Route exact path="/commissioner-agreement" component={CommissionerAgreement} />
        <Route exact path="/creator-agreement" component={CreatorAgreement} />
        <Route exact path="/policy-archive" component={PolicyArchive} />
        <Route path="/archive" component={PolicyArchiveRouter} />
        <Route exact path="/commission-types" component={CommissionTypes} />
        <Route exact path="/" component={LandingPage} />
        <Route path="/server-error" component={ServerError} />
        <Route path="/request/" component={InvalidSong} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}

const App: React.FC = () => {
  const [firebaseInitialized, setFirebaseInitialized] = React.useState(false);
  React.useEffect(() => {
    const asyncCall = async () => {
      await InitializeFirebase();
      setFirebaseInitialized(true);
    };
    asyncCall();
  }, []);

  if (!firebaseInitialized) return null;
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <HelmetProvider>
          {t.FontFaces}
          <Global styles={t.Global} />
          <AccountProvider>
            <SiteWidePromoProvider>
              <SongPlayingProvider>
                <SwitchesProvider id={'default'}>
                  <Switch>
                    <Route path="/admin">
                      <AdminRouter />
                    </Route>
                    <Route path="/">
                      <MainRouter />
                    </Route>
                  </Switch>
                </SwitchesProvider>
                <CookieBanner />
              </SongPlayingProvider>
            </SiteWidePromoProvider>
          </AccountProvider>
        </HelmetProvider>
      </Router>
    </React.Fragment>
  );
};

export default App;
